import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : {
      access: "",
      username: "",
      isAuth: false,
    };

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.access = action.payload.access;
      state.username = action.payload.username;
      state.isAuth = action.payload.isAuth;
      localStorage.setItem("user", JSON.stringify(action.payload));
    },
    logoutSuccess: (state, action) => {
      state.access = null;
      state.username = null;
      state.isAuth = false;

      localStorage.removeItem("user");
    },
  },
});

export const { loginSuccess, logoutSuccess } = userSlice.actions;

export const selectUserName = (state) => state.user.name;
export const accessToken = (state) => state.user.access;
export const selectAuthenticated = (state) => state.user.isAuth;

export default userSlice.reducer;
