import "./App.css";
import React, { lazy, Suspense } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes as Switch,
} from "react-router-dom";

import { ButtonBase, CircularProgress } from "@mui/material";
import styled from "styled-components";
import CacheBuster from "./CacheBuster";
import { useSelector } from "react-redux";
import Loader from "./components/Loader/Loader";
import PageNotFound from "./components/PageNotFound";
const CreateOrganization = lazy(() =>
  import("./pages/Organization/CreateOrganization")
);
const Navigation = lazy(() => import("./pages/Routes/Navigation"));
const SignIn = lazy(() => import("./pages/Auth/SignIn"));
const SignUp = lazy(() => import("./pages/Auth/SignUp"));

function App() {
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }
        return (
          <Suspense fallback={<Loader />}>
            <Router>
              <Switch>
                <Route path="/" element={<SignIn />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route
                  path="/create-company"
                  element={<CreateOrganization />}
                />
                <Route path="/dashboard/*" element={<Navigation />} />
                <Route component={PageNotFound} />
              </Switch>
            </Router>
          </Suspense>
        );
      }}
    </CacheBuster>
  );
}

export default App;
