import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { selectAuthenticated } from "../slices/user/userSlice";
import styled from "styled-components";

const PageNotFound = (props) => {
  const [state, setState] = useState(true);
  const navigate = useNavigate();
  const isAuthenticated = useSelector(selectAuthenticated);
  const handleLogin = (e) => {
    navigate("/signin");
  };

  return (
    <Container>
      <Wrapper>
        <Header>
          <LogoContainer>
            <Logo src="images/logo.svg" alt="logo" />
          </LogoContainer>
          <NavContainer>
            <NavLink to="">Pricing</NavLink>
            <NavLink to="">Support</NavLink>
            <NavLink to="">Blog</NavLink>
          </NavContainer>
          {!isAuthenticated && (
            <HeaderButtonGroup>
              <TryButton type="button">Try Viknbooks for free</TryButton>
              <LoginButton type="button" onClick={(e) => handleLogin(e)}>
                Login
              </LoginButton>
            </HeaderButtonGroup>
          )}
        </Header>
        <PageNotFoundContainer>
          <PageNotFoundImageContainer>
            <PageNotFoundImage src="images/pagenotfound.png" />
          </PageNotFoundImageContainer>
        </PageNotFoundContainer>

        <BottomLinks>
          <LinkGroups>
            <LinkHeading>Keep up to date with Viknbooks</LinkHeading>
            <LinkText>Product updates</LinkText>
            <LinkText>Pricing</LinkText>
            <LinkText>Blog</LinkText>
          </LinkGroups>
          <LinkGroups className="center">
            <LinkHeading>Our Products</LinkHeading>
            <LinkText>Viknbooks</LinkText>
            <LinkText>Viknbooks Mobile</LinkText>
            <LinkText>Rassasy Mobile</LinkText>
          </LinkGroups>
          <LinkGroups className="right">
            <LinkHeading>Support</LinkHeading>
            <LinkText>Get Support</LinkText>
            <LinkText>Viknbooks Mobile</LinkText>
            <LinkText>RTake The Tutorial</LinkText>
          </LinkGroups>
        </BottomLinks>
        <SocialMedia>
          <SocialMediaHeading>Socials</SocialMediaHeading>
          <SocialIconGroup>
            <SocialIconContainer>
              <SocialIcon
                href="https://www.instagram.com/vikncodes/?hl=en"
                className="icon-instagram"
                target="_blank"
              ></SocialIcon>
            </SocialIconContainer>
            <SocialIconContainer>
              <SocialIcon
                href="https://www.facebook.com/vikncodesllp"
                className="icon-facebook-squared"
                target="_blank"
              ></SocialIcon>
            </SocialIconContainer>
            <SocialIconContainer>
              <SocialIcon
                href="https://twitter.com/vikncodes?s=11"
                className="icon-twitter"
                target="_blank"
              ></SocialIcon>
            </SocialIconContainer>
            <SocialIconContainer>
              <SocialIcon
                href="https://www.facebook.com/vikncodesllp"
                className="icon-youtube-play"
                target="_blank"
              ></SocialIcon>
            </SocialIconContainer>
            <SocialIconContainer>
              <SocialIcon
                href="https://www.facebook.com/vikncodesllp"
                className="icon-linkedin-squared"
                target="_blank"
              ></SocialIcon>
            </SocialIconContainer>
          </SocialIconGroup>
        </SocialMedia>
        <Copyright>
          <CopyRightText>
            © {new Date().getFullYear()} Vikn Codes LLP. All rights reserved.
            Viknbooks is a trademarks of Vikn Codes LLP.
          </CopyRightText>
          <PrivacyContainer>
            <PrivacyLink>Legal</PrivacyLink>
            <PrivacyLink>Privacy Policy</PrivacyLink>
          </PrivacyContainer>
        </Copyright>
      </Wrapper>
    </Container>
  );
};

export default PageNotFound;

const Container = styled.div`
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: -webkit-radial-gradient(
      top center,
      ellipse cover,
      rgba(0, 212, 255, 0) 50%,
      rgba(255, 255, 255, 1) 100%
    );
    background-size: 100vw 179vh;
    /* background: linear-gradient(
      180deg,
      rgba(0, 212, 255, 0) 65%,
      rgba(255, 255, 255, 1) 100%
    ); */
  }
  ${({ load }) =>
    load === true &&
    `
height: 100vh;
  `}
`;
const Wrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
  z-index: 10;
`;
const Header = styled.div`
  padding: 20px 0;
  z-index: 10;
  display: grid;
  grid-template-columns: 0.3fr 1fr 0.31fr;
  align-items: center;
  @media screen and (max-width: 1290px) {
    grid-template-columns: 0.3fr 1fr 0.4fr;
  }
`;
const LogoContainer = styled.div`
  width: 180px;
`;
const Logo = styled.img`
  width: 100%;
`;
const NavContainer = styled.div``;
const NavLink = styled(Link)`
  margin-right: 30px;
  font-size: 14px;
`;
const HeaderButtonGroup = styled.div``;
const TryButton = styled.button`
  margin-right: 10px;
  cursor: pointer;
  background: #fff;
  border: 2px solid #307ed9;
  border-radius: 30px;
  padding: 7px 15px;
`;
const LoginButton = styled.button`
  color: #fff;
  border: 0;
  cursor: pointer;
  background: #fff;
  border-radius: 30px;
  padding: 9px 20px;
  background: linear-gradient(
    180deg,
    rgba(44, 193, 25, 1) 32%,
    rgba(25, 123, 15, 1) 100%
  );
`;
const Spotlight = styled.div`
  z-index: 10;
`;
const SpotlightHeadingContainer = styled.div`
  width: 40%;
  margin: 75px auto 0 auto;
`;
const SpotlightHeading = styled.p`
  font-size: 55px;
  font-weight: bold;
  text-align: center;
  line-height: 65px;
  margin-bottom: 10px;
`;
const SpotlightSubHeading = styled.p`
  font-size: 18px;
  color: #2666ba;
  text-align: center;
`;
const TutorialContainer = styled.div`
  text-align: center;
`;
const TutorialText = styled.p`
  display: inline;
  margin-right: 10px;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 20px;
`;
const TutorialLink = styled.a`
  color: #016186;
`;
const DashboardImageContainer = styled.div`
  position: relative;
`;
const DashboardLapFrameContainer = styled.div`
  width: 85%;
  margin: 0 auto;
`;
const LapFrame = styled.img`
  width: 100%;
`;
const DashboardFrameContainer = styled.div`
  position: absolute;
  top: 70px;
  left: 0;
  width: 65%;
  right: 0;
  margin: 0 auto;
  border-radius: 15px;
`;
const DashboardFrame = styled.img`
  border-radius: inherit;
  width: 100%;
  filter: blur(1px);
`;
const Finance = styled.div`
  width: 70%;
  margin: 50px auto;
`;
const FinanceHeading = styled.p`
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  line-height: 60px;
  margin-bottom: 10px;
`;
const FinanceSubHeading = styled.p`
  font-size: 16px;
  color: #484848;
  text-align: center;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 40px;
`;
const FinanceGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
`;
const FinanceGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
`;
const FinanceQuoteContainer = styled.div`
  border: 1px solid #878787;
  border-radius: 30px;
  padding: 40px;
  position: relative;
  background: #f9f9f9;
`;
const FinanceQuoteHeading = styled.p`
  width: 65%;
  font-weight: bold;
  font-size: 25px;
  b {
    font-weight: bold;
    font-size: 25px;
  }
`;
const FinanceQuoteSubHeading = styled.p`
  font-size: 16px;
  color: #4a4a4a;
  margin-bottom: 10px;
`;
const FinanceQuote = styled.div`
  color: #747474;
  padding: 27px 90px 0 10px;
  line-height: 20px;
  position: relative;
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    content: url(images/invertedcomma.svg);
  }
`;
const FinanceQuoteAuther = styled.div`
  position: absolute;
  bottom: 45px;
  right: 45px;
`;
const FinanceGridOptionText = styled.p`
  font-weight: bold;
  color: #ffffff;
  font-size: 16px;
  position: relative;
  z-index: 10;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 0;
    height: 2px;
    background: #fff;
    left: 0;
    transition: all 0.3s ease;
  }
`;

const FinanceGridOption = styled.div`
  ${({ typeid }) =>
    typeid == "1" &&
    `
    background: url("images/easy-invoicing.png");
  `}
  ${({ typeid }) =>
    typeid == "2" &&
    `
    background: url("images/reporting.png");
  `}
     ${({ typeid }) =>
    typeid == "3" &&
    `
    background: url("images/inventory.png");
  `}
       ${({ typeid }) =>
    typeid == "4" &&
    `
    background: url("images/explore-more.jpg");
    
  `}
  background-size: cover;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 216.5px;
  &:after {
    border-radius: 10px;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(0 0 0 / 36%);
  }
  &:hover {
    ${FinanceGridOptionText}:after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: #fff;
      left: 0;
      transition: all 0.3s ease;
    }
  }
  &:hover:after {
    background: rgba(0, 0, 0, 0.8);
    transition: all 0.3s ease;
  }
`;

const GoMobile = styled.div`
  position: relative;
  background: linear-gradient(
    135deg,
    rgba(51, 157, 205, 1) 0%,
    rgba(39, 100, 187, 1) 100%
  );
  border-radius: 20px;
  padding: 40px;
  width: 70%;
  margin: 125px auto;
`;
const GoMobileHeading = styled.p`
  width: 35%;
  font-size: 40px;
  font-weight: bold;
  color: #fff;
`;
const GoMobileSubHeading = styled.p`
  font-size: 25px;
  color: #fff;
`;
const PlayStoreImageContainer = styled.a`
  &.right {
    margin-left: auto;
  }
  display: block;
  width: 195px;
`;
const PlayStoreImage = styled.img`
  width: 100%;
`;
const MobileContainer = styled.div`
  &.mobile-frame-login {
    width: 238px;
    left: 109px;
    top: -10px;
    right: unset;
    transform: rotate(12deg);
    z-index: 3;
  }
  &.mobile-frame-password {
    width: 238px;
    left: -22px;
    top: -10px;
    right: unset;
    z-index: 1;
  }
  &.rassasy-login {
    border-radius: 15px;
    width: 195px;
    left: 130px;
    right: unset;
    transform: rotate(12deg);
    z-index: 4;
  }
  &.rassasy-password {
    border-radius: 15px;
    width: 195px;
    left: 0;
    right: unset;
    z-index: 2;
  }
  position: absolute;
  width: 370px;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;
const MobileImage = styled.img`
  border-radius: inherit;
  width: 100%;
`;

const TryVikn = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
  position: relative;
  background: linear-gradient(
    135deg,
    rgba(189, 254, 253, 1) 0%,
    rgba(132, 202, 255, 1) 100%
  );
  border-radius: 20px;
  padding: 40px;
  width: 70%;
  margin: 125px auto 50px;
  color: #000;
`;
const TryViknHeading = styled.p`
  width: 80%;
  font-size: 40px;
  font-weight: bold;
  line-height: 50px;
  color: #000;
`;
const TryViknSubHeading = styled.p`
  font-size: 12px;
  color: #656666;
  width: 75%;
`;
const TryViknLeft = styled.div``;
const TryViknRight = styled.div`
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const GoMobileRassasy = styled.div`
  position: relative;
  background: #1f1f1f;
  border-radius: 20px;
  padding: 40px;
  width: 70%;
  margin: 165px auto;
`;
const GoMobileRassasyTextContainer = styled.p`
  width: 50%;
  margin-left: auto;
`;
const GoMobileRassasyHeading = styled.p`
  font-size: 40px;
  font-weight: bold;
  color: #fff;
`;
const GoMobileRassasySubHeading = styled.p`
  font-size: 35px;
  color: #fff;
`;
const AvailableText = styled.p`
  color: #fff;
  margin-top: 25px;
  text-align: right;
`;
const TryButtonGroup = styled.div``;
const TrialButton = styled.button`
  cursor: pointer;
  color: #0053c9;
  padding: 10px 20px;
  border: 0;
  background: transparent;
  text-transform: capitalize;
  border-radius: 25px;
  transition: all 0.3s ease;
  &:hover {
    cursor: pointer;
    color: #ffffff;
    background: #072739;
    border: 0;
    border-radius: 25px;
    padding: 10px 20px;
    transition: all 0.3s ease;
  }
`;
const ChooseButton = styled.button`
  cursor: pointer;
  color: #3179e0;
  padding: 10px 20px;
  border: 0;
  background: transparent;
`;
const Speciality = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;
const PointsContainer = styled.div`
  display: flex;
  margin-left: auto;
`;
const Tick = styled.div`
  width: 15px;
  height: 7px;
  border-bottom: 2px solid #38a1e8;
  border-left: 2px solid #38a1e8;
  transform: rotate(315deg);
`;
const PointText = styled.div`
  margin-left: 5px;
`;
const BottomLinks = styled.div`
  width: 70%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
`;
const LinkGroups = styled.div`
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
`;
const LinkHeading = styled.p`
  color: #000;
  font-size: 16px;
  margin-bottom: 10px;
`;
const LinkText = styled(Link)`
  color: #939393;
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
`;
const SocialMedia = styled.div`
  width: 70%;
  margin: 40px auto;
`;
const SocialMediaHeading = styled.div`
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
`;
const SocialIconGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SocialIconContainer = styled.div`
  cursor: pointer;
  border: 1px solid;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  text-align: center;

  font-size: 18px;
  color: #000;
  margin-right: 10px;
  transition: all 0.3s ease;
  &:last-child {
    margin: 0;
  }
  &:hover {
    background: #000;
    color: #ffffff;
    transition: all 0.3s ease;
  }
`;
const SocialIcon = styled.a`
  font-size: inherit;
  color: inherit;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: inherit;
  }
`;
const Copyright = styled.div`
  text-align: center;
  padding-bottom: 25px;
`;
const CopyRightText = styled.p`
  color: #888888;

  font-size: 14px;
  margin-bottom: 10px;
`;
const PrivacyContainer = styled.div`
  font-size: 14px;
`;
const PrivacyLink = styled(Link)`
  margin-right: 10px;
`;
const RassasyLogoContainer = styled.div`
  width: 250px;
`;
const RassasyLogo = styled.img`
  width: 100%;
`;
const PageNotFoundContainer = styled.div`
  width: 80%;
  margin: 0 auto;
`;
const PageNotFoundImageContainer = styled.div`
  width: 500px;
  margin: 50px auto;
`;
const PageNotFoundImage = styled.img`
  width: 100%;
`;
