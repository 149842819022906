// import "./loader.scss"

import { CircularProgress } from "@mui/material";
import styled from "styled-components";

const Loader = (props) => (
  <Box className="Loader">
    <CircularProgress />
  </Box>
);

export default Loader;

const Box = styled.div`
  width: 96%;
  height: 80vh;
  display: grid;
  place-items: center;
`;
